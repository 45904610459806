.body-div{
    height: 700px;
    background-color: #c2c1c1;
    font-family: 'Montserrat', sans-serif;
    margin: 0rem 1rem;
    overflow: auto;
}

h1 {
    margin: 2rem 0rem 0.3rem 0rem;
}

h2 {
    margin: 1.6rem 0rem 0.3rem 0rem;
}

p {
    margin: 0rem 0rem 0.4rem 0rem;
    line-height: 20px;
}

a:link {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: darkblue;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: black;
  background-color: transparent;
  text-decoration: underline;
}
